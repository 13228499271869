import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage as Image } from "gatsby-plugin-image"

import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`{
  magazines: allMdx(
    filter: {fields: {category: {eq: "magazines"}}}
    sort: {fields: fields___date, order: DESC}
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          description
          authors
          coverImage {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

const Grid = ({children}) => <div className="mx-4 grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">{children}</div>

const Magazine = ({magazine}) => {
  return (
    <Link className="flex flex-col text-gray-900" to={`/verhalen/${magazine.frontmatter.slug}`}>
      <Image image={magazine.frontmatter.coverImage.childImageSharp.gatsbyImageData} alt={magazine.frontmatter.title} />
      <p className="mt-4 text-3xl font-semibold text-red-700">
        {magazine.frontmatter.title}
      </p>
      <p className="text-lg text-gray-900 font-medium mt-2">{magazine.frontmatter.authors.join(" – ")}</p>
    </Link>
  );
}

const Magazines = ({data, location}) => {
  return (
    <Layout>
      <SEO title="Alle verhalen van Vonk Magazine" path={location.pathname} />
      <Wide>
        <Grid>
          {data.magazines.edges.map(({node}) => {
            return (
              <Magazine key={node.frontmatter.slug} magazine={node} />
            )
          })}
        </Grid>
      </Wide>
    </Layout>
  )
}

export default Magazines
